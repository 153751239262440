<section>
  <div class="container-fluid bg-colorgrey">
    <div class="row m-0">
      <div class="col-md-4 mt-2 p-0">
        <div class="wifd120">
          <label>Select Academic Year</label>
          <select class="form-select" [(ngModel)]="acdemicYearId" (change)="selectAcademicYearId($event)">
            <option *ngFor="let year of academicYears" [value]="year.id" class="p-1">{{year.year}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <div class="montop getlowest_filds" *ngIf="schoolType === 'BOTH'">
          <label class="monopad">Switch to High School </label>
          <label class="switch">
            <input
              type="checkbox"
              [(ngModel)]="isHighSchool"
              (change)="loadWorkbook()"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <!-- <div class="row m-0">
                    <div class="col-md-12">
                        <div class="text-left">

                            <ul class="ul-decial">
                                <span class="system-head">System-Wide Goals</span>
                                <li> 75% of students at each school will achieve annual typical growth on i-Ready
                                    Reading and
                                    Math assessments by 2024-25</li>
                                <li>75% of students at each school will “Strongly Agree” that “God is present in my
                                    life.” by 2024-25</li>
                                <li>Each school will be rated Strong on at least 3/5 Essentials, including Supportive
                                    Environment, at the conclusion of 2024-25</li>
                                <li>Each school will fully enroll its Kindergarten by 2024</li>
                                <li>Each school will operate within an approved budget</li>
                            </ul>
                        </div>
                    </div>

                </div> -->
      </div>
      <div class="col-md-4 p-0" *ngIf="schools.length > 0">
        <div class="getlowest_filds">
          <ul>
            <li>
              <span class="position-relative" (mouseleave)="closeOptions()">
                <label>Select School</label><br />
                <input
                  type="text"
                  class="comment_fild getlowest_fild fild_bg"
                  [formControl]="searchText"
                  (focus)="enableFilter = true"
                  #searchInput
                />
                <div class="search-lister" *ngIf="enableFilter">
                  <ul>
                    <li
                      *ngFor="let school of filteredSchools | async"
                      [value]="school.schoolId"
                      (click)="selectSchool(school)"
                    >
                      {{ school.name }}
                    </li>
                  </ul>
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8 pr-0">
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="row m-0">
              <div class="col-md-12 col-lg-6 pl-0">
                <div
                  *ngIf="!isHighSchool"
                  class="card min-height-290 mt-2 mb-2"
                >
                  <div class="card-body position-relative">
                    <div class="d-flex mt-2">
                      <div class="card-title font-weight-600">
                        <a
                          href="https://sso.mapnwea.org/auth/login?dest=https%3A%2F%2Fapi.nwea.org%2Foidc%2Flogin%3Fclient_id%3DVL9Jp7aDpQqXGRVhhdrtV2GvlXXE1Z5B%26sessionid%3Drrt-0211eb0ffbf3197fd-c-ea-4898-82472599-2"
                          target="_blank"
                          >Academic Performance Summary</a
                        >
                      </div>
                      <div
                        class="posabs12"
                        [ngClass]="
                          flags.prfAcademics > 0 ? 'redmark' : 'greenmark'
                        "
                      ></div>
                    </div>
                    <div class="content-page">
                      <canvas
                        width="400"
                        height="230"
                        #academicChartAvg
                      ></canvas>
                      <div class="sub-title mt-2 text-center">{{labels[acdemicYearId || 1]['APS']}}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isHighSchool" class="card min-height-290 mt-2 mb-2">
                  <div class="card-body position-relative">
                    <div class="d-flex mt-2">
                      <div class="card-title font-weight-600">
                        <a
                          href="https://sso.mapnwea.org/auth/login?dest=https%3A%2F%2Fapi.nwea.org%2Foidc%2Flogin%3Fclient_id%3DVL9Jp7aDpQqXGRVhhdrtV2GvlXXE1Z5B%26sessionid%3Drrt-0211eb0ffbf3197fd-c-ea-4898-82472599-2"
                          target="_blank"
                          >Comparative Performance Summary</a
                        >
                      </div>
                      <!-- <div class="posabs12"
                                                [ngClass]="flags.prfAcademics > 0 ? 'redmark' : 'greenmark'"></div> -->
                    </div>
                    <div class="content-page">
                      <canvas
                        width="400"
                        height="230"
                        #comparativePerformanceChart
                      ></canvas>
                      <div class="sub-title mt-2 text-center">
                        Avg. SAT Scores by Section
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card min-height-290 mt-2 mb-2">
                  <div class="card-body position-relative">
                    <div class="d-flex mt-2">
                      <!-- <div class="card-title font-weight-600"><a [href]="CIScorecards"
                                                    target="_blank">Catholic Identity Summary</a></div> -->
                      <div class="card-title font-weight-600">
                        <a
                          href="https://test.arktest.org/users/sign_in"
                          target="_blank"
                          >ARK Score Breakdown</a
                        >
                      </div>
                      <div class="pl-2">
                        <div class="colorboxes d-flex">
                          <div class="greensquare"></div>
                          - High Score
                        </div>
                        <div class="colorboxes d-flex">
                          <div class="moderasquare"></div>
                          - Moderate Score
                        </div>
                        <div class="colorboxes d-flex">
                          <div class="lowsquare"></div>
                          - Low Score
                        </div>
                      </div>
                      <div
                        class="posabs12"
                        [ngClass]="
                          flags.catholicIdentity > 0 ? 'redmark' : 'greenmark'
                        "
                      ></div>
                    </div>
                    <div class="content-page">
                      <canvas width="400" height="200" #identityChart></canvas>
                      <div class="sub-title mt-2 text-center">
                        % of Students in Score Category
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 pl-0">
                <div
                  *ngIf="!isHighSchool"
                  class="card min-height-290 mt-2 mb-2"
                >
                  <div class="card-body pt-155 position-relative">
                    <div class="mt-2">
                      <div class="d-flex mt-2">
                        <div class="card-title font-weight-600">
                          <a
                            href="https://sso.mapnwea.org/auth/login?dest=https%3A%2F%2Fapi.nwea.org%2Foidc%2Flogin%3Fclient_id%3DVL9Jp7aDpQqXGRVhhdrtV2GvlXXE1Z5B%26sessionid%3Drrt-0211eb0ffbf3197fd-c-ea-4898-82472599-2"
                            target="_blank"
                            >Academic Growth Summary</a
                          >
                        </div>
                        <div
                          class="posabs12"
                          [ngClass]="
                            flags.growthAcademics > 0 ? 'redmark' : 'greenmark'
                          "
                        ></div>
                      </div>
                      <div class="content-page">
                        <canvas
                          width="400"
                          height="230"
                          #academicChart
                        ></canvas>
                        <div class="sub-title mt-2 text-center">
                          {{labels[acdemicYearId || 1]['AGS']}}
                        </div>
                      </div>
                      <!-- <div class="card-title font-weight-600"><a>School Survey Summary</a>
                                            </div>
                                            <div class="posabs12"
                                                [ngClass]="flags.academics > 0 ? 'redmark' : 'greenmark'">
                                            </div>
                                            <div class="workinprgrs">
                                                <img src="../../assets/images/workinprgrs.jpg">
                                            </div> -->
                    </div>
                  </div>
                </div>
                <div *ngIf="isHighSchool" class="card min-height-290 mt-2 mb-2">
                  <div class="card-body pt-155 position-relative">
                    <div class="mt-2">
                      <div class="d-flex mt-2">
                        <div class="card-title font-weight-600">
                          <a
                            href="https://sso.mapnwea.org/auth/login?dest=https%3A%2F%2Fapi.nwea.org%2Foidc%2Flogin%3Fclient_id%3DVL9Jp7aDpQqXGRVhhdrtV2GvlXXE1Z5B%26sessionid%3Drrt-0211eb0ffbf3197fd-c-ea-4898-82472599-2"
                            target="_blank"
                            >Academic Performance Summary</a
                          >
                        </div>
                        <div
                          class="posabs12"
                          [ngClass]="
                            flags.growthAcademics > 0 ? 'redmark' : 'greenmark'
                          "
                        ></div>
                      </div>
                      <div class="content-page">
                        <canvas
                          width="400"
                          height="230"
                          #highSchoolPerformace
                        ></canvas>
                        <div class="sub-title mt-2 text-center">
                          2023 PSAT/SAT Scores
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="card min-height-290  mt-2 mb-2">
                                    <div class="card-body pt-155 position-relative">
                                        <div class="d-flex mt-2">
                                            <div class="card-title font-weight-600"><a
                                                    href="https://www.5-essentials.org/" target="_blank">5 Essentials
                                                    Performance Summary</a>
                                            </div>
                                            <div class="posabs12"
                                                [ngClass]="flags.essentials > 0 ? 'redmark' : 'greenmark'"></div>
                                        </div>
                                        <div class="content-page">
                                            <div class="sub-title margin-20">Overall Performance <span
                                                    [ngClass]="{'status-code-well-organized': essentials.overallPerformance == 'Well-Organized', 'status-code-organized': essentials.overallPerformance == 'Organized', 'status-code-moderate' : essentials.overallPerformance == 'Moderately Organized', 'status-code-partially-organized' : essentials.overallPerformance == 'Partially Organized', 'status-code-no-organized' : essentials.overallPerformance == 'Not Yet Organized', 'status-code-low' : essentials.overallPerformance == 'Low Response'}">{{essentials.overallPerformance}}</span>
                                            </div>
                                            <div class="boxer-ranger mt-2">
                                                <div class="boxer-20">Effective Leaders</div>
                                                <div class="boxer-20">Collaborative Teachers</div>
                                                <div class="boxer-20">Involved Families</div>
                                                <div class="boxer-20">Supportive Environment</div>
                                                <div class="boxer-20">Ambitious Instruction</div>
                                            </div>
                                            <div class="boxer-ranger firstbox">
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.effectiveLeaders == 'Very Strong', 'essentials-code-organized': essentials.effectiveLeaders == 'Strong', 'essentials-code-moderate' : essentials.effectiveLeaders == 'Neutral', 'essentials-code-partially-organized' : essentials.effectiveLeaders == 'Weak', 'essentials-code-no-organized' : essentials.effectiveLeaders == 'Very Weak', 'essentials-code-low' : essentials.effectiveLeaders == 'Low Response'}">
                                                    {{essentials.effectiveLeaders}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.collaborativeTeachers == 'Very Strong', 'essentials-code-organized': essentials.collaborativeTeachers == 'Strong', 'essentials-code-moderate' : essentials.collaborativeTeachers == 'Neutral', 'essentials-code-partially-organized' : essentials.collaborativeTeachers == 'Weak', 'essentials-code-no-organized' : essentials.collaborativeTeachers == 'Very Weak', 'essentials-code-low' : essentials.collaborativeTeachers == 'Low Response'}">
                                                    {{essentials.collaborativeTeachers}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.involvedFamilies == 'Very Strong', 'essentials-code-organized': essentials.involvedFamilies == 'Strong', 'essentials-code-moderate' : essentials.involvedFamilies == 'Neutral', 'essentials-code-partially-organized' : essentials.involvedFamilies == 'Weak', 'essentials-code-no-organized' : essentials.involvedFamilies == 'Very Weak', 'essentials-code-low' : essentials.involvedFamilies == 'Low Response'}">
                                                    {{essentials.involvedFamilies}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.supportiveEnvironment == 'Very Strong', 'essentials-code-organized': essentials.supportiveEnvironment == 'Strong', 'essentials-code-moderate' : essentials.supportiveEnvironment == 'Neutral', 'essentials-code-partially-organized' : essentials.supportiveEnvironment == 'Weak', 'essentials-code-no-organized' : essentials.supportiveEnvironment == 'Very Weak', 'essentials-code-low' : essentials.supportiveEnvironment == 'Low Response'}">
                                                    {{essentials.supportiveEnvironment}}</div>
                                                <div class="boxer-20"
                                                    [ngClass]="{'essentials-code-well-organized': essentials.ambitiousInstruction == 'Very Strong', 'essentials-code-organized': essentials.ambitiousInstruction == 'Strong', 'essentials-code-moderate' : essentials.ambitiousInstruction == 'Neutral', 'essentials-code-partially-organized' : essentials.ambitiousInstruction == 'Weak', 'essentials-code-no-organized' : essentials.ambitiousInstruction == 'Very Weak', 'essentials-code-low' : essentials.ambitiousInstruction == 'Low Response'}">
                                                    {{essentials.ambitiousInstruction}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                <div class="card min-height-290 mt-2 mb-2">
                  <div class="card-body position-relative">
                    <div class="d-flex mt-2">
                      <div class="card-title font-weight-600">
                        <a
                          href="https://dart.renweb.com/Login?ReturnUrl=%2fDART%2fNCEA%2fschoolSubmission%2f"
                          target="_blank"
                        >
                          Enrollment Summary
                        </a>
                      </div>
                      <div
                        class="posabs12"
                        [ngClass]="
                          flags.enrollment > 0 ? 'redmark' : 'greenmark'
                        "
                      ></div>
                    </div>
                    <div class="content-page">
                      <canvas
                        width="400"
                        height="230"
                        #enrollmentChart
                      ></canvas>
                    </div>
                    <div class="sub-title mt-2 text-center">
                      Avg. Enrollment in K-2 =
                      <span
                        [ngClass]="
                          averageEnrollment < 20 ? 'status-code-red-2' : ''
                        "
                        >{{ averageEnrollment }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-0">
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="card mt-2 mb-2">
              <div class="card-body">
                <div class="menu-title">Flag Notification</div>
                <div class="box-div">
                  <div class="d-flex mt-2">
                    <span
                      class="mr-2"
                      [ngClass]="flags.academics > 0 ? 'redmark' : 'greenmark'"
                    ></span>
                    <div class="card-title font-weight-600">
                      <a
                        
                        target="_blank"
                        >ACADEMICS</a
                      >
                    </div>
                  </div>
                  <div class="content-box">
                    <ul>
                      <li
                        *ngFor="let flag of academicFlags | keyvalue"
                        [hidden]="flag.value == 'N' || flag.value == null"
                      >
                        {{ flag.key }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="box-div">
                  <div class="d-flex mt-2">
                    <span
                      class="mr-2"
                      [ngClass]="flags.essentials > 0 ? 'redmark' : 'greenmark'"
                    ></span>
                    <div class="card-title font-weight-600">
                      <a
                        
                        target="_blank"
                        >5 ESSENTIALS</a
                      >
                    </div>
                  </div>
                  <div class="content-box">
                    <ul>
                      <li
                        *ngFor="let flag of essentialsFlags | keyvalue"
                        [hidden]="flag.value == 'N' || flag.value == null"
                      >
                        {{ flag.key }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="box-div">
                  <div class="d-flex mt-2">
                    <span
                      class="mr-2"
                      [ngClass]="
                        flags.catholicIdentity > 0 ? 'redmark' : 'greenmark'
                      "
                    ></span>
                    <div class="card-title font-weight-600">
                      <a
                        
                        target="_blank"
                        >CATHOLIC IDENTITY</a
                      >
                    </div>
                  </div>
                  <div class="content-box">
                    <ul>
                      <li
                        *ngFor="let flag of catholicIdentity | keyvalue"
                        [hidden]="flag.value == 'N' || flag.value == null"
                      >
                        {{ flag.key }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="box-div">
                  <div class="d-flex mt-2">
                    <span
                      class="mr-2"
                      [ngClass]="flags.enrollment > 0 ? 'redmark' : 'greenmark'"
                    ></span>
                    <div class="card-title font-weight-600">
                      <div>ENROLLMENT</div>
                    </div>
                  </div>
                  <div class="content-box">
                    <ul>
                      <li
                        *ngFor="let flag of enrollmentOperationsFlag | keyvalue"
                        [hidden]="flag.value == 'N' || flag.value == null"
                      >
                        {{ flag.key }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12 p-0">
                        <div class="card mt-2 mb-2">
                            <div class="card-body">
                                <div class="mt-2">
                                    <div class="card-title font-weight-600"><a>School Survey Summary</a>
                                    </div>
                                    <div class="workinprgrs">
                                        <img src="../../assets/images/workinprgrs.jpg">
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div> -->
          <div class="col-md-12 p-0">
            <div class="card mt-2 mb-2">
              <div class="card-body">
                <div class="card-title font-weight-600">
                  Blue Ribbon Calculator
                </div>
                <div class="row m-0">
                  <div class="col-md-12 p-0">
                    <div class="ribin">
                      <img src="../../assets/images/blueribbion.png" />
                    </div>
                  </div>
                  <!-- <div class="col-6 text-right">
                                        <div>
                                            <a class="color-black pr-2 font-weight-500" [href]="blueRibbon?.Fall" target="_blank">Fall {{blueRibbon?.year}}</a>
                                        </div>
                                    </div> -->
                  <div class="col-12 text-center">
                    <div>
                      <a
                        class="card-title font-weight-500"
                        [href]="blueRibbon?.Spring"
                        target="_blank"
                        >Spring {{ blueRibbon?.year }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
